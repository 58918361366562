const round = (number, digits = 3) => {
    return Math.round(number * Math.pow(10, digits)) / Math.pow(10, digits);
};

const PrettyNumber = (props) => {
    const numberConfig = { minimumFractionDigits: 2 };

    const percentConfig = { minimumFractionDigits: 3 };
    switch (props.type) {
        case "currency":
            if (props.number < 0) {
                return (
                    <span className="text-danger mr-2">
                        {`R$ ${round(props.number, 3).toLocaleString(
                            "pt-BR",
                            numberConfig
                        )}`}
                    </span>
                );
            } else {
                return (
                    <span className="text-success mr-2">
                        {`R$ ${round(props.number, 3).toLocaleString(
                            "pt-BR",
                            numberConfig
                        )}`}
                    </span>
                );
            }
            break;

        case "percent":
            if (props.number < 0) {
                return (
                    <span className="text-danger mr-2">
                        <i className="fa fa-arrow-down" />{" "}
                        {round(props.number * 100, 3).toLocaleString(
                            "pt-BR",
                            percentConfig
                        )}
                        %
                    </span>
                );
            } else {
                return (
                    <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" />{" "}
                        {round(props.number * 100, 3).toLocaleString(
                            "pt-BR",
                            percentConfig
                        )}
                        %
                    </span>
                );
            }
            break;
        case "percentNoArrow":
            if (props.number < 0) {
                return (
                    <span className="text-danger mr-2">
                        {round(props.number * 100, 3).toLocaleString(
                            "pt-BR",
                            percentConfig
                        )}
                        %
                    </span>
                );
            } else {
                return (
                    <span className="text-success mr-2">
                        {round(props.number * 100, 3).toLocaleString(
                            "pt-BR",
                            percentConfig
                        )}
                        %
                    </span>
                );
            }
            break;

        case "float":
            if (props.number < 0) {
                return round(props.number, 3).toLocaleString(
                    "pt-BR",
                    numberConfig
                );
            } else {
                return round(props.number, 3).toLocaleString(
                    "pt-BR",
                    numberConfig
                );
            }
            break;

        case "integer":
            if (props.number < 0) {
                return round(props.number, 0).toLocaleString("pt-BR");
            } else {
                return round(props.number, 0).toLocaleString("pt-BR");
            }
            break;
    }
};

export default PrettyNumber;
