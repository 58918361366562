import { createSlice } from "@reduxjs/toolkit";

export const initialStatePortfolio = {
    portfolios: {
        fiaPortfolio: null,
    },
};

const updatePortfolio = (state, action) => {
    state.portfolios.fiaPortfolio = {
        carteira: action.payload.carteira,
        benchmarkIndex: action.payload.benchmarkIndex,
        portfolioVar: action.payload.portfolioVar,
        enquadramento: action.payload.enquadramento,
        currentDate: action.payload.currentDate,
    };
};

const fiaPortfolioSlice = createSlice({
    name: "fia-portfolio",
    initialState: initialStatePortfolio,
    reducers: {
        updatePortfolio,
    },
});

export const fiaPortfolioActions = fiaPortfolioSlice.actions;

export default fiaPortfolioSlice;
