import { investorsSliceActions } from "./investors";
import { notificationsActions } from "./notifications";
import responseNotification from "components/utils/responseNotification";

import { auth0SliceActions } from "./auth0";
import store from "store";

export const fetchInvestors = () => {
    return async (dispatch, getState) => {
        dispatch(
            notificationsActions.showNotificationInvestors({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + "investors/capitalraising/available",
                {
                    method: "GET",
                    headers: { Authorization: "Bearer " + accessToken },
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationInvestors,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const data = await response.json();

            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const newInvestors = await fetchData(accessToken);
            dispatch(investorsSliceActions.updateInvestidosInvestidores(newInvestors));
            dispatch(
                notificationsActions.showNotificationInvestors({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API retornou algo suculento",
                })
            );
        
        } catch (error) {
            console.log(error);
        }
    };
};


export const fetchInvestorsTarget = (payload) => {
    return async (dispatch, getState) => {
        dispatch(
            notificationsActions.showNotificationInvestors({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + "investors/capitalraising/target",
                {
                    method: "POST",
                    headers: { Authorization: "Bearer " + accessToken },
                    body: JSON.stringify(payload)
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationInvestors,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const data = await response.json();

            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const investorTarget = await fetchData(accessToken, payload);

            if(investorTarget.investidosFromInvestidores) {
                dispatch(investorsSliceActions.updateInvestidosFromInvestidores(investorTarget));

            } else if (investorTarget.investidoresFromInvestidos) {
                dispatch(investorsSliceActions.updateInvestidoresFromInvestidos(investorTarget));

            }
            dispatch(
                notificationsActions.showNotificationInvestors({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API retornou algo suculento",
                })
            );
            
        } catch (error) {
            console.log(error);
        }
    };
};
