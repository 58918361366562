import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PrettyNumber from "components/utils/PrettyNumber";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchInvestorsTarget } from "store/investors-actions";

const InvestidosTable = (props) => {
    const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState(null);
    const [investor, setInvestor] = useState(null);
    const onClick = (event, selectedRow) => {
        setInvestor(selectedRow.GESTOR_FUNDO_INVESTIDO);
        setSelectedRow(selectedRow.tableData.id);
    };

    useEffect(() => {
        if (investor) {
            dispatch(
                fetchInvestorsTarget({
                    type: "investidores",
                    gestorNome: investor,
                })
            );
        }
    }, [dispatch, investor]);

    return (
        <MaterialTable
            title="Investidos"
            data={props.investidos}
            columns={[
                {
                    title: "Gestor Fundo Investido",
                    field: "GESTOR_FUNDO_INVESTIDO",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.GESTOR_FUNDO_INVESTIDO.toLowerCase().includes(
                            term.toLowerCase()
                        );
                    },
                    filtering: true,
                },
                {
                    title: "Valor em milhões",
                    field: "VALOR",
                    filtering: false,
                    align: "right",
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.VALOR}
                                type="currency"
                            />
                        );
                    },
                },
            ]}
            onRowClick={onClick}
            localization={{
                body: {
                    emptyDataSourceMessage: "Nenhum registro para exibir",
                },
                toolbar: {
                    searchTooltip: "Pesquisar",
                },
                pagination: {
                    labelRowsSelect: "linhas",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            options={{
                filtering: true,
                search: false,
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 50],
                exportAllData: true,

                headerStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                },

                rowStyle: (rowData) => ({
                    backgroundColor:
                        selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                    "font-size": 12,
                }),
                exportMenu: [
                    {
                        label: "Export PDF",
                        exportFunc: (cols, datas) =>
                            ExportPdf(
                                cols,
                                datas,
                                `${props.portfolioSelected}`
                            ),
                    },
                    {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                            ExportCsv(
                                cols,
                                datas,
                                `${props.portfolioSelected}`
                            ),
                    },
                ],
            }}
        />
    );
};

export default InvestidosTable;
