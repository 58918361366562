/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Comparator from "views/examples/Comparator.js";
import Portfolio from "views/examples/Portfolio.js";
import Captacao from "views/examples/Captacao.js";
import EventoCorp from "views/examples/EventoCorp";
var routes = [
    {
        path: "/index",
        name: "Portfolio",
        icon: "ni ni-bullet-list-67 text-white",
        iconDark: "ni ni-bullet-list-67 text-primary",
        component: Portfolio,
        layout: "/index",
    },
    {
        path: "/comparator",
        name: "Comparator",
        icon: "ni ni-spaceship text-white",
        iconDark: "ni ni-spaceship text-primary",
        component: Comparator,
        layout: "/index",
    },
    {
        path: "/evento_corp",
        name: "Evento Corp",
        icon: "ni ni-book-bookmark text-white",
        iconDark: "ni ni-book-bookmark text-primary",
        component: EventoCorp,
        layout: "/index",
    },
    {
        path: "/captacao",
        name: "Captação",
        icon: "ni ni-tv-2 text-white",
        iconDark: "ni ni-tv-2 text-primary",
        component: Captacao,
        layout: "/index",
    },
];
export default routes;
