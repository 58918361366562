import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PrettyNumber from "components/utils/PrettyNumber";
const EventCorpTable = (props) => {
    return (
        <MaterialTable
            title={"Eventos Corporativos"}
            data={props.eventCorp}
            columns={[
                {
                    title: "ReportDate",
                    field: "reportdate",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.reportdate
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "SpecialExDate",
                    field: "specialexdate",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.specialexdate
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "ProcessCode",
                    field: "processcode",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.processcode
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "PaymentDate",
                    field: "paymentdate",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.paymentdate
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "Id",
                    field: "id",
                    filtering: false,
                },
                {
                    title: "DataStatus",
                    field: "data_status",
                    filtering: false,
                },
                {
                    title: "EventDescription",
                    field: "event_description",
                    filtering: false,
                },
                {
                    title: "EventType",
                    field: "event_type",
                    filtering: false,
                },
                {
                    title: "TickerSymbol",
                    field: "tickersymbol",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.isinorigin
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "IsinOrigin",
                    field: "isinorigin",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.isinorigin
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "IsinProduct",
                    field: "isinproduct",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.isinorigin
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "EventValue",
                    field: "event_value",
                    filtering: false,
                    align: "right",
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.event_value}
                                type="float"
                            />
                        );
                    },
                },
                {
                    title: "Market",
                    field: "marketidentifiercode",
                    filtering: false,
                },
            ]}
            localization={{
                body: {
                    emptyDataSourceMessage: "Nenhum registro para exibir",
                },
                toolbar: {
                    searchTooltip: "Pesquisar",
                },
                pagination: {
                    labelRowsSelect: "linhas",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            options={{
                filtering: true,
                search: false,
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 30, 50],
                exportAllData: true,                rowStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                    "font-size": 12,
                },
                headerStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                },
                exportMenu: [
                    {
                        label: "Export PDF",
                        exportFunc: (cols, datas) =>
                            ExportPdf(
                                cols,
                                datas,
                                `${props.portfolioSelected}`
                            ),
                    },
                    {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                            ExportCsv(
                                cols,
                                datas,
                                `${props.portfolioSelected}`
                            ),
                    },
                ],
            }}
        />
    );
};

export default EventCorpTable;
