import React, { useEffect, useState } from "react";
import {
    useLocation,
    Route,
    Switch,
    Redirect,
    useHistory,
} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";

import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchComparisonOptions } from "store/metric-plots-actions";
import { auth0SliceActions } from "store/auth0";
import {fetchInvestors} from "store/investors-actions";
import {fetchEventCorp} from "store/event-corp-actions";

let firstRun = true;
const Admin = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();
    const isAuthenticated = useSelector((state) => state.auth0.isAuthenticated);
    const { getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();

    const [accessToken, setAcessToken] = useState(null);

    if (!isAuthenticated) {
        firstRun = true;
        logout();
    }

    if (firstRun) {
        getAccessTokenSilently().then((result) => {
            setAcessToken(result);
        });

        firstRun = false;
    }
    dispatch(auth0SliceActions.updateAccessToken({ accessToken: accessToken }));
    
    useEffect(() => {
        if (typeof accessToken == "string") {
            dispatch(fetchComparisonOptions());
        }
    }, [dispatch, accessToken]);

    useEffect(() => {
        if (typeof accessToken == "string") {
            dispatch(fetchInvestors());
        }
    }, [dispatch, accessToken]);


    useEffect(() => {
        if (typeof accessToken == "string") {
            dispatch(fetchEventCorp());
        }
    }, [dispatch, accessToken]);



    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/index") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (
                props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };

    return (
        <>
            <Sidebar
                {...props}
                routes={routes}
                logo={{
                    innerLink: "/index",
                    imgSrc: require("../assets/img/brand/pc_logo_white.png")
                        .default,
                    imgAlt: "...",
                }}

                logoCollapsed={{
                    innerLink: "/index",
                    imgSrc: require("../assets/img/brand/logo_planalto_capital_rgb_positivo_PNG.png")
                        .default,
                    imgAlt: "...",
                }}
            />
            <div className="main-content" ref={mainContent}>
                <AdminNavbar {...props} />
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/" to="/index/index" />
                </Switch>
                <Container fluid></Container>
            </div>
        </>
    );
};

export default Admin;
