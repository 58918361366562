import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import { Container, Button, Card } from "reactstrap";

// core components
import { AsyncPaginate } from "react-select-async-paginate";
import RowWithTwoCols from "components/utils/RowWithTwoCols";
import Header from "components/Headers/Header.js";
import CardWithGraph from "components/Charts/CardWithGraph";
import { metricPlotActions } from "store/metric-plots";
import { fetchMetricPlots } from "store/metric-plots-actions";
import DateRangeSlider from "components/utils/DateRangeSlider";
import LinePercentChart from "components/Charts/LinePercentChart";
import LineChart from "components/Charts/LineChart";
import DdChart from "components/Charts/DdChart";
import NotificationTop from "components/notifications/NotificationTop";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "components/utils/Loading";
import Pako from "pako";
import { base64ToArrayBuffer, createAndDownloadBlobFile } from "components/utils/downloadFile";
const sleep = (ms) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });

const Comparator = () => {
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.auth0.accessToken);
    const notificationTop = useSelector(
        (state) => state.notifications.notificationTop
    );
    const [optionsSelected, setOptionsSelected] = useState([]);

    const metricPlots = useSelector((state) => state.metricPlots);
    useEffect(() => {
        if (typeof accessToken == "string") {
            if (metricPlots.toFetchPlot) {
                if (metricPlots.optionsSelected.length > 0) {
                    dispatch(fetchMetricPlots());
                }
            }
        }
    }, [
        dispatch,
        accessToken,
        metricPlots.optionsSelected,
        metricPlots.toFetchPlot,
    ]);

    const toggleButton = () => {
        dispatch(
            metricPlotActions.updateOptionsSelected({
                optionsSelected: optionsSelected,
            })
        );

        dispatch(metricPlotActions.setTrueToFetchPlot());
    };

    const loadOptions = async (search, prevOptions) => {
        let options = metricPlots.comparisonOptions;
        await sleep(100);

        let filteredOptions;
        if (!search) {
            filteredOptions = options;
        } else {
            const searchLower = search.toLowerCase();

            filteredOptions = options.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
        }

        const hasMore = filteredOptions.length > prevOptions.length + 10;
        const slicedOptions = filteredOptions.slice(
            prevOptions.length,
            prevOptions.length + 50
        );

        return {
            options: slicedOptions,
            hasMore,
        };
    };

    const { isAuthenticated, isLoading, logout } = useAuth0();


    const downloadRds = () => {
        let binario
        if(metricPlots.plots.rawRDS) {
            binario = base64ToArrayBuffer(metricPlots.plots.rawRDS);

            binario = Pako.inflate(binario)
            createAndDownloadBlobFile(binario, "metricas", "RDS")
        }
        
    }


    if (!isAuthenticated) {
        if (!isLoading) {
            logout();
        }
        return <Loading />;
    }

    return (
        <>
            {notificationTop && (
                <NotificationTop
                    status={notificationTop.status}
                    title={notificationTop.title}
                    message={notificationTop.message}
                />
            )}

            <Header
                container={
                    <Container className="mt--6" fluid>
                        <RowWithTwoCols
                            colLeftSize="10"
                            colRighttSize="2"
                            leftComponent={
                                <AsyncPaginate
                                    isMulti
                                    loadOptions={loadOptions}
                                    closeMenuOnSelect={false}
                                    onChange={setOptionsSelected}
                                    name="comparisonOptions"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Selecione os items que deseja comparar"
                                />
                            }
                            rightComponent={
                                <>
                                    <Button
                                        onClick={toggleButton}
                                        color="primary"
                                    >
                                        Go
                                    </Button>
                                    <Button
                                        onClick={downloadRds}
                                        color="primary"
                                    >
                                        Download
                                    </Button>
                                </>
                            }
                        />
                    </Container>
                }
            />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {metricPlots.plots.beginDate != null &&
                    metricPlots.plots.endDate != null && (
                        <RowWithTwoCols
                            leftComponent={
                                <DateRangeSlider
                                    dateArray={metricPlots.plots.dateRange}
                                    maxDate={metricPlots.plots.maxEndDate}
                                    minDate={metricPlots.plots.minBeginDate}
                                />
                            }
                            colLeftSize="12"
                            colRighttSize="0"
                        />
                    )}

                {metricPlots.plots.beginDate != null &&
                    metricPlots.plots.endDate != null && (
                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="Retorno Acumulado"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .retorno
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="Retorno: Acumulado Anualizado 252 (n = Desde o Início)"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .retorno_total
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}

                {metricPlots.plots.beginDate != null &&
                    metricPlots.plots.endDate != null && (
                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="Vol: anualizada como 252 (n = 21)"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .vol_anualizada
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="Vol: anualizada como 252 (n = Desde o início)"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .vol_anualizada_total
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}

                {metricPlots.plots.beginDate != null &&
                    metricPlots.plots.endDate != null && (
                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="Sortino: Anualizado como 252 (n = 252)"
                                    graph={
                                        <LineChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .sortino_anualizada_12m
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="Sortino: Anualizado como 252 (n = Desde o Início)"
                                    graph={
                                        <LineChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .sortino_anualizada_total
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}

                {metricPlots.plots.beginDate != null &&
                    metricPlots.plots.endDate != null && (
                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="Cvar: Desde o início (p = 95%)"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .cvar_p_95_desde_inicio
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="DrawDown: DrawDown"
                                    graph={
                                        <DdChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .drawdown
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}

                {metricPlots.plots.beginDate != null &&
                    metricPlots.plots.endDate != null && (
                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="DrawDown: Período médio UnderWater (n = 252)"
                                    graph={
                                        <LineChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .mean_length_draw_down_12m
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="DrawDown: Período médio UnderWater (n = Desde o início)"
                                    graph={
                                        <LineChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .mean_length_draw_down_total
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}

                {metricPlots.plots.beginDate != null &&
                    metricPlots.plots.endDate != null && (
                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="DrawDown: Profundidade média (n = 252)"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .mean_draw_down_12m
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="DrawDown: Profundidade média (n = Desde o início)"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .mean_draw_down_total
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}

                {metricPlots.plots.beginDate != null &&
                    metricPlots.plots.endDate != null && (
                        <RowWithTwoCols
                            leftComponent={
                                <CardWithGraph
                                    title="Sharpe SD: Anualizado como 252 (n = 252)"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .sharpe_sdanualizada_12m
                                            }
                                        />
                                    }
                                />
                            }
                            colLeftSize="6"
                            rightComponent={
                                <CardWithGraph
                                    title="Sharpe SD: Anualizado como 252 (n = Desde o início)"
                                    graph={
                                        <LinePercentChart
                                            data={
                                                metricPlots.plots.plotData
                                                    .sharpe_sdanualizada_total
                                            }
                                        />
                                    }
                                />
                            }
                            colRighttSize="6"
                        />
                    )}
            </Container>
        </>
    );
};

export default Comparator;
