import {
    Card,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Col,
    Container,
    Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import { useSelector } from "react-redux";
import SummaryCard from "components/CardBoards/SummaryCard";
import PortfolioTable from "components/Table/PortfolioTable";
import Loading from "components/utils/Loading";
import React, { useEffect, useState } from "react";
import { fetchFiaPortfiolio } from "store/fia-protfolio-actions";
import { useDispatch } from "react-redux";
import NotificationTop from "components/notifications/NotificationTop";
import { useInterval } from "components/utils/useInterval";
const Portfolio = () => {
    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.auth0.accessToken);

    useEffect(() => {
        if (typeof accessToken == "string") {
            dispatch(fetchFiaPortfiolio());
        }
    }, [dispatch,accessToken]);



    useInterval(() => {
        if (typeof accessToken == "string") {
            dispatch(fetchFiaPortfiolio());
        }
    }, 1000*20);

    const notificationPortfolio = useSelector(
        (state) => state.notifications.notificationPortfolio
    );
    const portfolioName = "Planalto Capital FIA";
    const portfolioSelected = portfolioName;

    const portfolios = useSelector((state) => state.portfolios.portfolios);

    return (
        <>
            {notificationPortfolio && (
                <NotificationTop
                    status={notificationPortfolio.status}
                    title={notificationPortfolio.title}
                    message={notificationPortfolio.message}
                />
            )}
            {!portfolios.fiaPortfolio && <Loading />}

            {portfolios.fiaPortfolio && (
                <>
                    <Header
                        container={
                            <Container fluid>
                                <div className="header-body">
                                    {/* Card stats */}
                                    <Row>
                                        <Col lg="4" xl="4">
                                            <SummaryCard
                                                title={`${portfolios.fiaPortfolio.benchmarkIndex.indexName} ${portfolios.fiaPortfolio.benchmarkIndex.lastTime}`}
                                                icon="ni ni-sound-wave"
                                                absoluteValue={
                                                    portfolios.fiaPortfolio
                                                        .benchmarkIndex
                                                        .rendimentoAbsoluto
                                                }
                                                absoluteType="integer"
                                                percentValue={
                                                    portfolios.fiaPortfolio
                                                        .benchmarkIndex
                                                        .rendimentoVariavel
                                                }
                                            />
                                        </Col>
                                        <Col lg="4" xl="4">
                                            <SummaryCard
                                                title={portfolioSelected}
                                                icon="ni ni-user-run"
                                                absoluteValue={
                                                    portfolios.fiaPortfolio
                                                        .portfolioVar
                                                        .rendimentoAbsoluto
                                                }
                                                absoluteType="integer"
                                                percentValue={
                                                    portfolios.fiaPortfolio
                                                        .portfolioVar
                                                        .rendimentoVariavel
                                                }
                                            />
                                        </Col>
                                        <Col lg="4" xl="4">
                                            <SummaryCard
                                                title="Enquadramento"
                                                icon="fas fa-money-bill-wave"
                                                absoluteValue={0}
                                                absoluteType="integer"
                                                percentValue={
                                                    portfolios.fiaPortfolio
                                                        .enquadramento
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        }
                    />

                    {/* Page content */}
                    <Container className="mt--7" fluid>
                        {/* Table */}
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <UncontrolledDropdown nav>
                                            <DropdownToggle className="pr-0">
                                                <Media className="align-items-left">
                                                    <Media className="ml-2 d-none d-lg-block">
                                                        {portfolioSelected}
                                                    </Media>
                                                </Media>
                                            </DropdownToggle>
                                            <DropdownMenu
                                                className="dropdown-menu-arrow"
                                                right
                                            >
                                                <DropdownItem
                                                    href="#pablo"
                                                    onClick={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    {portfolioName}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>

                                        {portfolios.fiaPortfolio
                                            .currentDate && (
                                            <h3>A carteira é do dia</h3>
                                        )}
                                        {!portfolios.fiaPortfolio
                                            .currentDate && (
                                            <h2> A carteira NÃO É DO DIA </h2>
                                        )}
                                    </CardHeader>

                                    <PortfolioTable
                                        portfolioSelected={portfolioSelected}
                                        portfolio={portfolios.fiaPortfolio}
                                    />
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </>
            )}
        </>
    );
};

export default Portfolio;
