import { notificationsActions } from "./notifications";
import { metricPlotActions } from "./metric-plots";
import { auth0SliceActions } from "./auth0";
import { base64ToArrayBuffer, compressedRawToString } from "components/utils/downloadFile";
import responseNotification from "components/utils/responseNotification";
import store from "store";

export const fetchMetricPlots = () => {
    return async (dispatch) => {
        dispatch(
            notificationsActions.showNotificationTop({
                status: "pending",
                title: "Aguarde!",
                message: "Consultando API",
            })
        );

        const fetchData = async (accessToken, payload) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + "comparison/metrics/univariate",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accessToken,
                    },
                    body: JSON.stringify(payload),
                }
            );

            responseNotification(
                response.status,
                notificationsActions.showNotificationTop,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            let data = await response.json();

            data = await base64ToArrayBuffer(data.rawJSON);
            data = await compressedRawToString(data)
            data = await JSON.parse(data)
            return data;
        };

        try {
            dispatch(metricPlotActions.setFalseToFetchPlot());
            const accessToken = store.getState().auth0.accessToken;
            const metricPlots = store.getState().metricPlots;
            const payloadToPost = {
                beginDate: metricPlots.plots.beginDate,
                endDate: metricPlots.plots.endDate,
                optionsSelected: metricPlots.optionsSelected,
            };
            const newPlots = await fetchData(accessToken, payloadToPost);

            dispatch(metricPlotActions.updateMetricPlot(newPlots));

            dispatch(
                notificationsActions.showNotificationTop({
                    status: "success",
                    title: "Sucesso!",
                    message: "A API retornou algo suculento",
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};

export const fetchComparisonOptions = () => {
    const apiUri = process.env.REACT_APP_DASHBOARD_API;
    return async (dispatch) => {
        const fetchData = async (accessToken) => {
            const response = await fetch(apiUri + "comparison/available", {
                method: "GET",
                headers: { Authorization: "Bearer " + accessToken },
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const data = await response.json();

            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;

            const newOptions = await fetchData(accessToken);

            dispatch(
                metricPlotActions.updatecomparisonOptions({
                    comparisonOptions: newOptions,
                })
            );
        } catch (error) {
            console.log(error);
        }
    };
};
