import Plot from "react-plotly.js";

const DdChart = (data) => {
    const layout = {
        autosize: true,
        font: { family: "roboto", size: 10, color: "#333333" },
        hovermode: "x",
        xaxis: {
            title: "",
            tickangle: "auto",
            type: "date",
            tickformat: "%Y-%m-%d",
            fixedrange: false,
        },
        yaxis: { title: "", tickformat: ".2f", fixedrange: false },
        legend: {
            orientation: "h",
        },
        separators: ",.",
        margin: { b: 70, l: 50, r: 0, t: 0, pad: 0, autoexpand: true },
    };

    const config = {
        responsive: true,
        displayModeBar: false,
        scrollZoom: false,
    };
    const rev = Math.random();
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Plot
                data={data.data.data}
                layout={layout}
                config={config}
                useResizeHandler={true}
                style={{ width: "100%", height: "100%" }}
                revision={rev}
            />
        </div>
    );
};

export default DdChart;
