// reactstrap components
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
// core components
import Header from "components/Headers/Header.js";
import RowWithTwoCols from "components/utils/RowWithTwoCols";
import InvestidosTable from "components/Table/InvestidosTable";
import NotificationTop from "components/notifications/NotificationTop";
import InvestidoresTable from "components/Table/InvestidoresTable";
import InvestidoresFromInvestidos from "components/Table/InvestidoresFromInvestidos";
import InvestidosFromInvestidores from "components/Table/InvestidosFromInvestidores";
const Captacao = () => {
    const notificationInvestors = useSelector(
        (state) => state.notifications.notificationInvestors
    );

    const investorsInvestidos = useSelector(
        (state) => state.investors.investors.investidos
    );
    const investorsInvestidores = useSelector(
        (state) => state.investors.investors.investidores
    );

    const investidosFromInvestidores = useSelector(
        (state) => state.investors.investors.investidosFromInvestidores
    );

    const investidoresFromInvestidos = useSelector(
        (state) => state.investors.investors.investidoresFromInvestidos
    );
    return (
        <>
            {notificationInvestors && (
                <NotificationTop
                    status={notificationInvestors.status}
                    title={notificationInvestors.title}
                    message={notificationInvestors.message}
                />
            )}

            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <RowWithTwoCols
                    colLeftSize="6"
                    colRighttSize="6"
                    leftComponent={
                        investorsInvestidores && (
                            <InvestidoresTable
                                investidores={investorsInvestidores}
                            />
                        )
                    }
                    rightComponent={
                        investidosFromInvestidores && (
                            <InvestidosFromInvestidores
                                investidosFromInvestidores={
                                    investidosFromInvestidores
                                }
                            />
                        )
                    }
                />
                <RowWithTwoCols
                    colLeftSize="6"
                    colRighttSize="6"
                    leftComponent={
                        investorsInvestidos && (
                            <InvestidosTable investidos={investorsInvestidos} />
                        )
                    }
                    rightComponent={
                        investidoresFromInvestidos && (
                            <InvestidoresFromInvestidos
                                investidoresFromInvestidos={
                                    investidoresFromInvestidos
                                }
                            />
                        )
                    }
                />
            </Container>
        </>
    );
};

export default Captacao;
