import { createSlice } from "@reduxjs/toolkit";

export const initialStateMetricPlot = {
    plots: {
        dateRange: null,
        beginDate: null,
        endDate: null,
        plotData: null,
        rawRDS: null,
    },
    optionsSelected: [],
    toFetchPlot: false,
    comparisonOptions: null,
};

const updateMetricPlot = (state, action) => {
    state.plots.dateRange = action.payload.dateRange;
    state.plots.beginDate = action.payload.beginDate;
    state.plots.endDate = action.payload.endDate;
    state.plots.plotData = action.payload.plotData;
    state.plots.rawRDS = action.payload.rawRDS;
};

const setFalseToFetchPlot = (state, action) => {
    state.toFetchPlot = false;
};

const setTrueToFetchPlot = (state, action) => {
    state.toFetchPlot = true;
};
const updateDateRange = (state, action) => {
    state.plots.beginDate = action.payload.beginDate;
    state.plots.endDate = action.payload.endDate;
};

const updateOptionsSelected = (state, action) => {
    state.plots.beginDate = null;
    state.plots.endDate = null;
    state.optionsSelected = action.payload.optionsSelected;
};

const updatecomparisonOptions = (state, action) => {
    state.comparisonOptions = action.payload.comparisonOptions;
};

const metricPlotSlice = createSlice({
    name: "metric-plot",
    initialState: initialStateMetricPlot,
    reducers: {
        updateMetricPlot,
        updatecomparisonOptions,
        setFalseToFetchPlot,
        setTrueToFetchPlot,
        updateDateRange,
        updateOptionsSelected,
    },
});

export const metricPlotActions = metricPlotSlice.actions;

export default metricPlotSlice;
