import { fiaPortfolioActions } from "./fia-portfolio";
import { notificationsActions } from "./notifications";
import responseNotification from "components/utils/responseNotification";
import { auth0SliceActions } from "./auth0";
import store from "store";

export const fetchFiaPortfiolio = () => {
    return async (dispatch, getState) => {
        const fetchData = async (accessToken, payload) => {
            const apiUri = process.env.REACT_APP_DASHBOARD_API;
            const response = await fetch(
                apiUri + "portfolio/portfolio_update",
                {
                    method: "POST",
                    headers: { Authorization: "Bearer " + accessToken },
                    body: JSON.stringify(payload)
                }
            );

            
            responseNotification(
                response.status,
                notificationsActions.showNotificationPortfolio,
                auth0SliceActions.updateIsAuthenticated,
                dispatch
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }
            const data = await response.json();

            return data;
        };

        try {
            const accessToken = store.getState().auth0.accessToken;
            const newPortfolio = await fetchData(accessToken, {portfolioName: "pc_fia_i"});

            dispatch(fiaPortfolioActions.updatePortfolio(newPortfolio));
        } catch (error) {
            console.log(error);
        }
    };
};
