import Pako from "pako";

export const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

export const compressedRawToString = (raw) => {
    const data = Pako.inflate(raw);


    var str = "";
    for (var i = 0; i < (data.length-1); ++i) {
        str += String.fromCharCode(data[i]);
    }
    return str;
};

export const createAndDownloadBlobFile = (
    body,
    filename,
    extension = "pdf"
) => {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, fileName);
    } else {
        const link = document.createElement("a");
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
