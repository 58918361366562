import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PrettyNumber from "components/utils/PrettyNumber";

const InvestidoresFromInvestidos = (props) => {
    return (
        <MaterialTable
            title="Investidores dos Investidos"
            data={props.investidoresFromInvestidos}
            columns={[
                {
                    title: "Gestor Fundo Investido",
                    field: "GESTOR_FUNDO_INVESTIDOR",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.GESTOR_FUNDO_INVESTIDOR.toLowerCase().includes(
                            term.toLowerCase()
                        );
                    },
                    filtering: true,
                },
                {
                    title: "Valor em milhões",
                    field: "VALOR",
                    filtering: false,
                    align: "right",
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.VALOR}
                                type="currency"
                            />
                        );
                    },
                },
            ]}
            localization={{
                body: {
                    emptyDataSourceMessage: "Nenhum registro para exibir",
                },
                toolbar: {
                    searchTooltip: "Pesquisar",
                },
                pagination: {
                    labelRowsSelect: "linhas",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            options={{
                filtering: true,
                search: false,
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 30, 50],
                exportAllData: true,
                rowStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                    "font-size": 12,
                },
                headerStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                },
                exportMenu: [
                    {
                        label: "Export PDF",
                        exportFunc: (cols, datas) =>
                            ExportPdf(
                                cols,
                                datas,
                                `${props.portfolioSelected}`
                            ),
                    },
                    {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                            ExportCsv(
                                cols,
                                datas,
                                `${props.portfolioSelected}`
                            ),
                    },
                ],
            }}
        />
    );
};

export default InvestidoresFromInvestidos;
