import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    eventCorp: null
};

const updateEventCorp = (state, action) => {
    state.eventCorp = action.payload.eventCorp;
};

const eventCorpSlice = createSlice({
    name: "auth0",
    initialState: initialState,
    reducers: {
        updateEventCorp
    },
});

export const eventCorpSliceActions = eventCorpSlice.actions;

export default eventCorpSlice;
