import { Slider } from "@material-ui/core";
import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Card, CardHeader, Row } from "reactstrap";
import { metricPlotActions } from "store/metric-plots";

import { useDispatch } from "react-redux";

const DateRangeSlider = (props) => {
    const dispatch = useDispatch();
    const indexOfMaxDate = (dateArray) => {
        return dateArray.length - 1;
    };

    const labelFormat = (index) => {
        return `${props.dateArray[index]}`;
    };

    const [value, setValue] = useState([0, indexOfMaxDate(props.dateArray)]);

    const handleChange = (event, newValue, activeThumb) => {
        const minDistance = 30;

        setValue([
            Math.min(newValue[0], value[1] - minDistance),
            Math.max(newValue[1], value[0] + minDistance),
        ]);
    };

    const onChangeCommitted = (event, newValue) => {
        dispatch(
            metricPlotActions.updateDateRange({
                beginDate: props.dateArray[newValue[0]],
                endDate: props.dateArray[newValue[1]],
            })
        );

        dispatch(metricPlotActions.setTrueToFetchPlot());
    };

    return (
        <Card className="shadow">
            <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                    <Typography gutterBottom>
                        <h3> Range de data: <strong>{labelFormat(value[0])}</strong>{" "}
                        - <strong>{labelFormat(value[1])}</strong>
                        </h3>
                    </Typography>
                    <Slider
                        onChangeCommitted={onChangeCommitted}
                        onChange={handleChange}
                        valueLabelDisplay="off"
                        value={value}
                        valueLabelFormat={labelFormat}
                        max={indexOfMaxDate(props.dateArray)}
                        min={0}
                    />
                    <div className="col"></div>
                </Row>
            </CardHeader>
        </Card>
    );
};

export default DateRangeSlider;
