import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    investors: {
        investidos: null,
        investidores: null,
        investidosFromInvestidores: null,
        investidoresFromInvestidos: null,
    },
};

const updateInvestidosInvestidores = (state, action) => {
    state.investors.investidos = action.payload.investidos;
    state.investors.investidores = action.payload.investidores;
};

const updateInvestidosFromInvestidores = (state, action) => {
    state.investors.investidosFromInvestidores =
        action.payload.investidosFromInvestidores;
};


const updateInvestidoresFromInvestidos = (state, action) => {

    state.investors.investidoresFromInvestidos =
        action.payload.investidoresFromInvestidos;
};


const investorsSlice = createSlice({
    name: "Investors",
    initialState: initialState,
    reducers: {
        updateInvestidosInvestidores,
        updateInvestidosFromInvestidores,
        updateInvestidoresFromInvestidos
    },
});

export const investorsSliceActions = investorsSlice.actions;

export default investorsSlice;
