import MaterialTable from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import PrettyNumber from "components/utils/PrettyNumber";
const PortfolioTable = (props) => {
    return (
        <MaterialTable
            title={props.portfolioSelected}
            data={props.portfolio.carteira}
            columns={[
                {
                    title: "Ativo",
                    field: "tickersymbol",
                    customFilterAndSearch: (term, rowData) => {
                        return rowData.tickersymbol
                            .toLowerCase()
                            .includes(term.toLowerCase());
                    },
                    filtering: true,
                },
                {
                    title: "Classe",
                    field: "class",
                    filtering: false,
                },
                {
                    title: "Quantidade",
                    field: "quantity",
                    filtering: false,
                    align: "right",
                    customSort: (a, b) => a.quantity - b.quantity,
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.quantity}
                                type="integer"
                            />
                        );
                    },
                },
                {
                    title: "Fechamento anterior",
                    field: "previous_closing_price",
                    filtering: false,
                    align: "right",
                    customSort: (a, b) =>
                        a.previous_closing_price - b.previous_closing_price,
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.previous_closing_price}
                                type="currency"
                            />
                        );
                    },
                },
                {
                    title: "Preço",
                    field: "last_price",
                    filtering: false,
                    align: "right",
                    customSort: (a, b) => a.last_price - b.last_price,
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.last_price}
                                type="currency"
                            />
                        );
                    },
                },
                {
                    title: "R$",
                    field: "value",
                    filtering: false,
                    align: "right",
                    customSort: (a, b) => a.value - b.value,
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.value}
                                type="currency"
                            />
                        );
                    },
                },
                {
                    title: "Peso no PL %",
                    field: "weight",
                    align: "right",
                    customSort: (a, b) => a.weight - b.weight,
                    filtering: false,
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.weight}
                                type="percentNoArrow"
                            />
                        );
                    },
                },
                {
                    title: "Contribuição %",
                    field: "contribution",
                    filtering: false,
                    customSort: (a, b) => a.contribution - b.contribution,
                    align: "right",
                    render: (rowData) => {
                        return (
                            <PrettyNumber
                                number={rowData.contribution}
                                type="percentNoArrow"
                            />
                        );
                    },
                },
                {
                    title: "Retorno %",
                    field: "var",
                    align: "right",
                    customSort: (a, b) => a.var - b.var,
                    filtering: false,
                    render: (rowData) => {
                        return (
                            <PrettyNumber number={rowData.var} type="percent" />
                        );
                    },
                },
            ]}
            localization={{
                body: {
                    emptyDataSourceMessage: "Nenhum registro para exibir",
                },
                toolbar: {
                    searchTooltip: "Pesquisar",
                },
                pagination: {
                    labelRowsSelect: "linhas",
                    labelDisplayedRows: "{count} de {from}-{to}",
                    firstTooltip: "Primeira página",
                    previousTooltip: "Página anterior",
                    nextTooltip: "Próxima página",
                    lastTooltip: "Última página",
                },
            }}
            options={{
                filtering: true,
                search: false,
                pageSize: 20,
                pageSizeOptions: [5, 10, 20, 30, 50],
                exportAllData: true,
                rowStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                    "font-size": 12,
                },
                headerStyle: {
                    color: "#6d6d6d",
                    "font-family": "Roboto, sans-serif",
                },
                exportMenu: [
                    {
                        label: "Export PDF",
                        exportFunc: (cols, datas) =>
                            ExportPdf(
                                cols,
                                datas,
                                `${props.portfolioSelected}`
                            ),
                    },
                    {
                        label: "Export CSV",
                        exportFunc: (cols, datas) =>
                            ExportCsv(
                                cols,
                                datas,
                                `${props.portfolioSelected}`
                            ),
                    },
                ],
            }}
        />
    );
};

export default PortfolioTable;
