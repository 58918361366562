// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
// core components
import Header from "components/Headers/Header.js";
import RowWithTwoCols from "components/utils/RowWithTwoCols";
import InvestidosTable from "components/Table/InvestidosTable";
import NotificationTop from "components/notifications/NotificationTop";
import InvestidoresTable from "components/Table/InvestidoresTable";
import InvestidoresFromInvestidos from "components/Table/InvestidoresFromInvestidos";
import InvestidosFromInvestidores from "components/Table/InvestidosFromInvestidores";
import EventCorpTable from "components/Table/EventCorpTable";
const EventoCorp = () => {
    const notificationEventCorp = useSelector(
        (state) => state.notifications.notificationEventCorp
    );

    const eventCorp = useSelector((state) => state.eventCorp.eventCorp);

    return (
        <>
            {notificationEventCorp && (
                <NotificationTop
                    status={notificationEventCorp.status}
                    title={notificationEventCorp.title}
                    message={notificationEventCorp.message}
                />
            )}

            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="" xl="12">
                        {eventCorp && <EventCorpTable eventCorp={eventCorp} />}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default EventoCorp;
